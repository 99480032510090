import './App.css';
import Login from './login/Login.js'
import Logout from './login/Logout.js'
import Day from './day/Day.js'
import Spinner from './UI/Spinner.js'
import React, { useState, useEffect, useCallback } from "react";
import { useCookies } from 'react-cookie';
import { getFromBackend } from './backend/backend.js'

const cookiesParams = { maxAge: 60 * 60 * 24 * 7 };

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['backendToken']);
  const [isFetchingToken, setIsFetchingToken] = useState(false);

  const handleLogin = useCallback(() => {
    const backendToken = cookies.backendToken;
    if (backendToken === undefined) {
      console.log("No backend token")
      setIsLoggedIn(false)
      return;
    }
    getFromBackend(backendToken, '/is_logged_in', ({ is_logged_in }) => {
      if (is_logged_in) {
        console.log("Logged in");
      } else {
        console.log("not logged in");
      }
      setIsLoggedIn(is_logged_in)
    }, setIsFetchingToken
    );
  }, [cookies]);

  useEffect(() => {
    handleLogin()
  }, [handleLogin]);


  return (
    <div className="App">
      <header className="App-header">
        {isFetchingToken && <div className='SpinnerContainer'><Spinner /></div>}
        {!isFetchingToken && <>
          {(isLoggedIn && (cookies.backendToken !== undefined)) && <><Day backendToken={cookies.backendToken} /><Logout backendToken={cookies.backendToken} removeBackendToken={() => { removeCookie('backendToken'); }} setSpinner={setIsFetchingToken} /></>}
          {!isLoggedIn && <Login setBackendToken={(token) => { setCookie('backendToken', token, cookiesParams); }} />}
        </>}
      </header>
    </div>
  );
}

export default App;

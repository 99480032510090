function pad(stuff) {
    return String(stuff).padStart(2, '0')
}

export function GetDayAsStr() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${pad(month)}-${pad(day)}`
}

export function GetDateAsStr() {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${GetDayAsStr()}-${pad(hours)}-${pad(minutes)}-${pad(seconds)}`
}
// const backend_address = 'http://localhost:5000'

const backend_address = 'https://kitty-diet.com/api'

const diet_backend_token_name = 'Diet-Backend-Token'

export function makeLoginRequest(loginForm, setBackendToken, setIsRunning = (_) => { }) {
    setIsRunning(true);
    fetch(backend_address + '/login', { method: "POST", body: loginForm })
        .then((response) => response.json())
        .then((response) => {
            const token = response[diet_backend_token_name]
            console.log(token);
            setBackendToken(token);
            setIsRunning(false);
        })
        .catch((error) => console.log(error));
}

export function getFromBackend(backendToken, address, process_response, setIsRunning = (_) => { }) {
    console.log(`Fetching ${address} using ${backendToken}`)
    setIsRunning(true);
    fetch(backend_address + address, {
        method: "GET", headers: {
            [diet_backend_token_name]: backendToken,
        }
    })
        .then((response) => response.json())
        .then((json) => {
            // console.log(json);
            process_response(json);
            setIsRunning(false);
        })
        .catch((error) => console.log(error))
}

export function postToBackend(backendToken, address, formData, process, setIsRunning = (_) => { }) {
    console.log(`Posting to ${address} using ${backendToken}`)
    setIsRunning(true);
    fetch(backend_address + address, {
        method: "POST", body: formData, headers: {
            [diet_backend_token_name]: backendToken,
        }
    })
        .then((response) => {
            // console.log(response);
            process(response);
            setIsRunning(false);
        })
        .catch((error) => console.log(error))
}
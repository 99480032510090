import React, { useState } from "react";
import { makeLoginRequest } from '../backend/backend.js'
import './Login.css';

export default function Login({ setBackendToken }) {
    const [userName, setUserName] = useState("")

    function handleSubmit(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        makeLoginRequest(formData, (token) => {
            setBackendToken(token);
        });
    }

    return <>
        <form className="LoginForm" onSubmit={handleSubmit}>
            <div>User Name</div>
            <input
                name='username'
                value={userName}
                label="Enter your user name"
                required
                onChange={(e) => setUserName(e.target.value)}
            />
            <hr className="Line" />
            <button type="submit">Login</button>
        </form>
    </>
}
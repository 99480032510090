import React, { useState, useEffect, useCallback } from "react";
// import Modal from 'react-modal';
import AddMeal from './AddMeal.js'
import Meal from './Meal.js'
import MealsSum from './MealsSum.js'
import Spinner from '../UI/Spinner.js'
import Modal from '../UI/Modal.js'

import './Day.css';


import { GetDayAsStr } from './DateHelpers'
import { getFromBackend, postToBackend } from '../backend/backend.js'


export default function Day({ backendToken }) {
  const [meals, setMeals] = useState([]);
  const [addMealModalIsOpen, setaddMealModalIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const day_str = GetDayAsStr();

  const refreshMeals = useCallback(() => {
    getFromBackend(backendToken, `/get_meals_for_day/${day_str}`, ({ meals }) => setMeals(meals), setIsFetching);
  }, [backendToken, day_str]);

  function deleteMeal(meal_id) {
    const formData = new FormData();
    formData.append('meal_id', meal_id);
    postToBackend(backendToken, '/delete_meal', formData, (_) => refreshMeals(), (v) => {
      if (v) {
        setIsFetching(true);
      }
    });
  }

  useEffect(() => {
    refreshMeals();
  }, [refreshMeals])

  function closeAddMealModal() {
    refreshMeals();
    setaddMealModalIsOpen(false);
  }

  const mealItems = meals.map(meal => <Meal key={meal.meal_id} deleteMeal={deleteMeal} backendToken={backendToken} meal_id={meal.meal_id} name={meal.name} quantity={meal.quantity} calories_density={meal.calories_density} />)
  const mealPage = <>
    <ul>
      {mealItems}
    </ul>
    <div className="BottomMeals">
      <MealsSum meals={meals} />
      <button onClick={() => setaddMealModalIsOpen(true)}>Add Food</button>
    </div>
  </>

  return <>
    {addMealModalIsOpen && <Modal><AddMeal close={closeAddMealModal} backendToken={backendToken} /></Modal>}
    <div className="Day">
      <h1>{day_str}</h1>
      {isFetching && <Spinner />}
      {!isFetching && mealPage}
    </div>
  </>
}
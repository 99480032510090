import React from "react";
import { postToBackend } from '../backend/backend.js'
import './Logout.css';

export default function Logout({ backendToken, removeBackendToken, setSpinner }) {
    function LogoutUser(e) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        postToBackend(backendToken, '/logout', formData, (_) => {
            removeBackendToken();
        }, setSpinner);
    }

    return <form className="LogoutFrom" onSubmit={LogoutUser}>
        <button className="LogoutButton" type="submit">Logout</button>
    </form>
}
import React, { useState, useEffect } from "react";
import Spinner from '../UI/Spinner.js'
import { GetDateAsStr } from './DateHelpers'
import { postToBackend, getFromBackend } from '../backend/backend.js'
import './AddMeal.css';

export default function AddMeal({ close, backendToken }) {
    const [name, setName] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [caloryDensity, setCaloryDensity] = useState(0);
    const [isFetching, setIsFetching] = useState(true);
    const [mealsSuggest, setMealsSuggest] = useState([]);
    const [showMealsSuggest, setShowMealsSuggest] = useState(false);

    function handleFocus(e) {
        e.target.select();
    }

    function handleFocusMealSuggest(e) {
        handleFocus(e);
        setShowMealsSuggest(true);
    }

    function copySuggestedMeal(meal_id) {
        const suggestMeal = mealsSuggest.find((e) => e['meal_id'] === meal_id);
        if (suggestMeal === undefined) {
            console.log(`Fail to find ${meal_id}`);
            return;
        }
        setName(suggestMeal['name']);
        setQuantity(suggestMeal['quantity'])
        setCaloryDensity(suggestMeal['calories_density']);
    }

    function handleLostFocusMealSuggest(e) {
        if (e.relatedTarget && e.relatedTarget.id.includes('SuggestedMealId')) {
            const meal_id = parseInt(e.relatedTarget.id.replace('SuggestedMealId', ''));
            console.log(`Suggested Choice: ${meal_id}`);
            copySuggestedMeal(meal_id);
        }
        setShowMealsSuggest(false);
    }

    function addNewMeal(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        formData.append('date', GetDateAsStr())
        postToBackend(backendToken, '/add_meal', formData, (_) => close(), setIsFetching)
    }

    useEffect(() => {
        getFromBackend(backendToken, '/get_meals_suggest', (response) => { setMealsSuggest(response['meals']) }, setIsFetching)
    }, [backendToken])

    function filterSuggestedMeal(mealsSuggest) {
        const search_key = name.toLowerCase();
        return mealsSuggest.filter((meal) => meal['name'].toLowerCase().includes(search_key));
    }

    const mealsSuggestList = <ul className="autocomplete-items">
        {filterSuggestedMeal(mealsSuggest).map(meal => <li key={meal['meal_id']}>
            <button id={`SuggestedMealId${meal['meal_id']}`} onClick={(e) => { e.preventDefault(); }} > {meal['name']}, {meal['calories_density']}cal, {meal['quantity']}g
            </button></li>)}
    </ul>

    const page = <>
        <form onSubmit={addNewMeal}>
            <div className="CancelButton">
                <button onClick={(e) => { e.preventDefault(); close(); }}>cancel</button>
            </div>
            <div>Food Name</div>
            <input
                list='foodNamesList'
                name='name'
                value={name}
                label="Food name"
                required
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                onFocus={handleFocusMealSuggest}
                onBlur={handleLostFocusMealSuggest}
            />
            {showMealsSuggest && mealsSuggestList}
            <hr />
            <div>Quantity</div>
            <input
                name='quantity'
                value={quantity}
                type="number"
                label="Food quantity"
                min="0"
                step="1"
                required
                autoComplete="off"
                onChange={(e) => setQuantity(e.target.value)}
                onFocus={handleFocus}
            />
            <hr />
            <div>Calories per 100g</div>
            <input
                name='calories_density'
                value={caloryDensity}
                type="number"
                label="Calories per 100g"
                min="0"
                step="1"
                required
                autoComplete="off"
                onChange={(e) => setCaloryDensity(e.target.value)}
                onFocus={handleFocus}
            />
            <hr />
            <div className="MealButtons">
                <button type="submit">Add</button>
            </div>
        </form>
    </>

    return <div className="AddMeal">
        {isFetching && <Spinner />}
        {!isFetching && page}
    </div>

}
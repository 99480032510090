import React from "react";
import './MealsSum.css';


export default function MealsSum({ meals }) {
    const sum = meals.map(m => m['quantity'] * m['calories_density'] / 100)
        .reduce((partial, x) => partial + x, 0);
    return <div className="MealsSum">
        Calories Eaten: {Math.round(sum)}cal
    </div>
}